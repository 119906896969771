<template>
    <div class="container">
        <router-link to="/impressum">Impressum</router-link>
    </div>
</template>

<script>
export default {
  name: 'BaseFooter'
}
</script>

<style scoped>
.container {
    padding: 20px;
    font-size: 9px;
    text-align: right;
}
</style>
